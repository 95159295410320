/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {  } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './ClaimPhotos.css';
import { cameraOutline, folderOutline, close, videocamOutline } from 'ionicons/icons';
import { CameraSource } from '@capacitor/camera';

import { t } from 'i18next';
import { Toaster } from 'react-hot-toast';
import NoPhotos from './NoPhotos';
import AddLevels from './AddLevels';
import AddPhotoToClaimForm from './AddPhotoToClaimForm';
import { ClaimPhotoMenuTabs } from '../pages/helper/ClaimPhotosHelper';
import ClaimPhotosHeader from '../molecule/ClaimPhotosHeader';
import ClaimPhotosGridImage from '../molecule/ClaimPhotosGridImage';
import ClaimPhotosRoomGridImage from '../molecule/ClaimPhotosRoomGridImage';
import useClaimPhotosViewModel, { PropsClaimPhotos } from './ClaimPhotosViewModel';
import BulkEditPhotoToClaimForm from './BulkEditPhotoToClaimForm';
import VideoUpload from '../atom/VideoUpload';
import NoVideos from './NoVideos';


const ClaimPhotos: React.FC<PropsClaimPhotos> = ({ claimIndx, phases, refetchPhotos }) => {
  const {
    backToRooms,
    photoSelectedOptions,
    selectedLevel,
    selectedRoom,
    setPhotoSelectedOptions,
    isLoading,
    isFetching,
    showNoPhoto,
    addNewToGalleryHandler,
    claimPhotosList,
    isSelectAll,
    isSelectEnabled,
    selectedPhotos,
    openLevelsModal,
    setIsAddPhotoToClaimFormOpen,
    setIsSelectAll,
    setIsSelectEnabled,
    setSelectedPhotoEdit,
    setSelectedPhotos,
    levelsList,
    selectRoom,
    isLevelsModalOpen,
    onRoomSelected,
    isAddPhotoToClaimFormOpen,
    claimPictures,
    onDismissAddPhotoToClaimForm,
    onSubmitAddPhotoToClaimForm,
    isEditBulkPhotoToClaimFormOpen,
    setIsEditBulkPhotoToClaimFormOpen,
    selectedBulkPhotoEdit,
    setSelectedBulkPhotoEdit,
    region,
    selectedPhotoEdit,
    claimVideosList,
    fileUploadClick,
    onSelectFile,
    fileUploadRef,
    showNoVideo,
  } = useClaimPhotosViewModel({ claimIndx, phases, refetchPhotos });

  return (
    <>
    <IonContent className='claim-photo-content'>
      <ClaimPhotosHeader 
        backToRooms={backToRooms}
        photoSelectedOptions={photoSelectedOptions}
        selectedLevel={selectedLevel}
        selectedRoom={selectedRoom}
        setPhotoSelectedOptions={setPhotoSelectedOptions}
      />
      {photoSelectedOptions === ClaimPhotoMenuTabs.Rooms &&
        <>
          {levelsList.length > 0 &&
            <ClaimPhotosRoomGridImage 
              levelsList={levelsList}
              selectRoom={selectRoom}
            />
          }
          <IonButton
            className='add-level-room-button'
            expand='block'
            onClick={() => openLevelsModal()}
          >
            {t('addLevelAndRoom').toString()}
          </IonButton>
        </>
      }      
      {photoSelectedOptions === ClaimPhotoMenuTabs.All &&
        <>
          <IonLoading
            isOpen={isLoading || isFetching}
            message={t('loadingPhotos')}
            duration={2000}
          />
          {showNoPhoto ?
            <NoPhotos
              addNewToGallery={addNewToGalleryHandler}
            /> :
            <ClaimPhotosGridImage
              claimIndx={claimIndx}
              claimPhotosList={claimPhotosList}
              isSelectAll={isSelectAll}
              isSelectEnabled={isSelectEnabled}
              selectedPhotos={selectedPhotos}
              openLevelsModal={openLevelsModal}
              setIsAddPhotoToClaimFormOpen={setIsAddPhotoToClaimFormOpen}
              setIsEditBulkPhotoToClaimFormOpen={setIsEditBulkPhotoToClaimFormOpen}
              setIsSelectAll={setIsSelectAll}
              setIsSelectEnabled={setIsSelectEnabled}
              setSelectedPhotoEdit={setSelectedPhotoEdit}
              setSelectedPhotos={setSelectedPhotos}
              setSelectedBulkPhotoEdit={setSelectedBulkPhotoEdit}
            />
          }
        </>
      }
      {photoSelectedOptions === ClaimPhotoMenuTabs.RoomDetails &&
        <>
          <IonLoading
            isOpen={isLoading || isFetching}
            message={t('loadingPhotos') || ''}
            duration={2000}
          />
          <ClaimPhotosGridImage
              claimIndx={claimIndx}
              claimPhotosList={claimPhotosList.filter((claimPhoto: { roomId: any; }) => claimPhoto.roomId === selectedRoom?.id)}
              isSelectAll={isSelectAll}
              isSelectEnabled={isSelectEnabled}
              selectedPhotos={selectedPhotos}
              openLevelsModal={openLevelsModal}
              setIsAddPhotoToClaimFormOpen={setIsAddPhotoToClaimFormOpen}
              setIsEditBulkPhotoToClaimFormOpen={setIsEditBulkPhotoToClaimFormOpen}
              setIsSelectAll={setIsSelectAll}
              setIsSelectEnabled={setIsSelectEnabled}
              setSelectedPhotoEdit={setSelectedPhotoEdit}
              setSelectedPhotos={setSelectedPhotos}
              setSelectedBulkPhotoEdit={setSelectedBulkPhotoEdit}
            />
        </>
      }
      {photoSelectedOptions === ClaimPhotoMenuTabs.Videos &&
        <>
          <IonLoading
            isOpen={isLoading || isFetching}
            message={t('loading')}
            duration={2000}
          />
          {showNoVideo ?
            <NoVideos /> :
            <ClaimPhotosGridImage
              claimIndx={claimIndx}
              claimPhotosList={claimVideosList}
              isSelectAll={false}
              isSelectEnabled={false}
              hideSelect
              selectedPhotos={[]}
              openLevelsModal={openLevelsModal}
              setIsAddPhotoToClaimFormOpen={setIsAddPhotoToClaimFormOpen}
              setIsEditBulkPhotoToClaimFormOpen={setIsEditBulkPhotoToClaimFormOpen}
              setIsSelectAll={setIsSelectAll}
              setIsSelectEnabled={setIsSelectEnabled}
              setSelectedPhotoEdit={setSelectedPhotoEdit}
              setSelectedPhotos={setSelectedPhotos}
              setSelectedBulkPhotoEdit={setSelectedBulkPhotoEdit}
            />
          }
        </>
      }
      </IonContent>
      <IonModal isOpen={isLevelsModalOpen} class="custom-modal">
        <IonHeader class="ion-no-border">
          <IonToolbar class="rooms-header">
            <IonTitle>{t('addImagesTo').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onRoomSelected()}>
                <div className="close-icon-container">
                  <IonIcon className="tools-close-button" icon={close} />
                </div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <AddLevels onDone={(room) => onRoomSelected(room)} claimIndx={claimIndx} />
      </IonModal>
      <AddPhotoToClaimForm
        isOpen={isAddPhotoToClaimFormOpen}
        claimPhotos={claimPictures}
        onDismiss={() => onDismissAddPhotoToClaimForm()}
        onSubmit={(success, msg) => onSubmitAddPhotoToClaimForm(success, msg)}
        region={region}
        claimIndex={claimIndx}
        phases={phases}
        editClaimPhoto={selectedPhotoEdit}
      />
      <BulkEditPhotoToClaimForm
        isOpen={isEditBulkPhotoToClaimFormOpen}
        onDismiss={() => onDismissAddPhotoToClaimForm()}
        onSubmit={(success) => onSubmitAddPhotoToClaimForm(success)}
        editClaimPhoto={selectedBulkPhotoEdit}
        claimIndex={claimIndx}
        phases={phases}
      />
      <IonFooter className="claim-details--footer">
        <IonButton
          fill="clear"
          className="claim-details--footer-buttons right-divider"
          onClick={() => addNewToGalleryHandler(CameraSource.Camera)}
        >
          <IonIcon icon={cameraOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          className="claim-details--footer-buttons right-divider"
          onClick={() => addNewToGalleryHandler(CameraSource.Photos)}
        >
          <IonIcon icon={folderOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          className="claim-details--footer-buttons"
          onClick={() => fileUploadClick()}
        >
          <IonIcon icon={videocamOutline} />
        </IonButton>
      </IonFooter>
      <Toaster
        containerStyle={{
          top: 100,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    <VideoUpload onSelectFile={(ev) => onSelectFile(ev)} ref={fileUploadRef} />
    </>
  );
};

export default ClaimPhotos;