import React, { useImperativeHandle, useRef } from 'react';

type Props = {
  onSelectFile: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

const VideoUpload = React.forwardRef<any, Props>(({
  onSelectFile,
}, ref) => {
  const fileInput = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    fileUpload() {
      fileInput?.current?.click();
    },
  }));

  return (
    <input
      ref={fileInput}
      hidden
      multiple={false}
      type="file"
      accept="video/mp4,video/x-m4v,video/*"
      onChange={(event) => onSelectFile(event)}
    />
  );
});

export default VideoUpload;
