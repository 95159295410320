import { TFunction } from 'i18next';

export const CALLED_IN_BY = [
  { label: 'Owner / Insured', value: 'H' },
  { label: 'Adjuster', value: 'A' },
  { label: 'Insurer', value: 'I' },
  { label: 'Broker', value: 'B' },
  { label: 'Property Mgr', value: 'P' },
  { label: 'Other', value: 'O' },
];

export const CATEGORIES = [
  { label: 'Residential', value: '1' },
  { label: 'Commercial', value: '2' },
];

export const CURRENCIES = [
  { label: 'CAD', value: '1' },
  { label: 'USD', value: '2' },
];


export const PROVINCES = [
  {
    code: 'CA',
    value: 'AB',
    label: 'Alberta',
  },
  {
    code: 'CA',
    value: 'BC',
    label: 'British Columbia',
  },
  {
    code: 'CA',
    value: 'MB',
    label: 'Manitoba',
  },
  {
    code: 'CA',
    value: 'NB',
    label: 'New Brunswick',
  },
  {
    code: 'CA',
    value: 'NL',
    label: 'Newfoundland and Labrador',
  },
  {
    code: 'CA',
    value: 'NS',
    label: 'Nova Scotia',
  },
  {
    code: 'CA',
    value: 'NT',
    label: 'Northwest Territories',
  },
  {
    code: 'CA',
    value: 'NU',
    label: 'Nunavut',
  },
  {
    code: 'CA',
    value: 'ON',
    label: 'Ontario',
  },
  {
    code: 'CA',
    value: 'PE',
    label: 'Prince Edward Island',
  },
  {
    code: 'CA',
    value: 'QC',
    label: 'Quebec',
  },
  {
    code: 'CA',
    value: 'SK',
    label: 'Saskatchewan',
  },
  {
    code: 'CA',
    value: 'YT',
    label: 'Yukon',
  },
  {
    code: 'US',
    value: 'AK',
    label: 'Alaska',
  },
  {
    code: 'US',
    value: 'AL',
    label: 'Alabama',
  },
  {
    code: 'US',
    value: 'AR',
    label: 'Arkansas',
  },
  {
    code: 'US',
    value: 'AZ',
    label: 'Arizona',
  },
  {
    code: 'US',
    value: 'CA',
    label: 'California',
  },
  {
    code: 'US',
    value: 'CO',
    label: 'Colorado',
  },
  {
    code: 'US',
    value: 'CT',
    label: 'Connecticut',
  },
  {
    code: 'US',
    value: 'DC',
    label: 'Dist Of Col',
  },
  {
    code: 'US',
    value: 'DE',
    label: 'Delaware',
  },
  {
    code: 'US',
    value: 'FL',
    label: 'Florvaluea',
  },
  {
    code: 'US',
    value: 'GA',
    label: 'Georgia',
  },
  {
    code: 'US',
    value: 'HI',
    label: 'Hawaii',
  },
  {
    code: 'US',
    value: 'IA',
    label: 'Iowa',
  },
  {
    code: 'US',
    value: 'ID',
    label: 'Idaho',
  },
  {
    code: 'US',
    value: 'IL',
    label: 'Illinois',
  },
  {
    code: 'US',
    value: 'IN',
    label: 'Indiana',
  },
  {
    code: 'US',
    value: 'KS',
    label: 'Kansas',
  },
  {
    code: 'US',
    value: 'KY',
    label: 'Kentucky',
  },
  {
    code: 'US',
    value: 'LA',
    label: 'Louisiana',
  },
  {
    code: 'US',
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    code: 'US',
    value: 'MD',
    label: 'Maryland',
  },
  {
    code: 'US',
    value: 'ME',
    label: 'Maine',
  },
  {
    code: 'US',
    value: 'MI',
    label: 'Michigan',
  },
  {
    code: 'US',
    value: 'MN',
    label: 'Minnesota',
  },
  {
    code: 'US',
    value: 'MO',
    label: 'Missouri',
  },
  {
    code: 'US',
    value: 'MS',
    label: 'Mississippi',
  },
  {
    code: 'US',
    value: 'MT',
    label: 'Montana',
  },
  {
    code: 'US',
    value: 'NC',
    label: 'North Carolina',
  },
  {
    code: 'US',
    value: 'ND',
    label: 'North Dakota',
  },
  {
    code: 'US',
    value: 'NE',
    label: 'Nebraska',
  },
  {
    code: 'US',
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    code: 'US',
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    code: 'US',
    value: 'NM',
    label: 'New Mexico',
  },
  {
    code: 'US',
    value: 'NV',
    label: 'Nevada',
  },
  {
    code: 'US',
    value: 'NY',
    label: 'New York',
  },
  {
    code: 'US',
    value: 'OH',
    label: 'Ohio',
  },
  {
    code: 'US',
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    code: 'US',
    value: 'OR',
    label: 'Oregon',
  },
  {
    code: 'US',
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    code: 'US',
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    code: 'US',
    value: 'SC',
    label: 'South Carolina',
  },
  {
    code: 'US',
    value: 'SD',
    label: 'South Dakota',
  },
  {
    code: 'US',
    value: 'TN',
    label: 'Tennessee',
  },
  {
    code: 'US',
    value: 'TX',
    label: 'Texas',
  },
  {
    code: 'US',
    value: 'UT',
    label: 'Utah',
  },
  {
    code: 'US',
    value: 'VA',
    label: 'Virginia',
  },
  {
    code: 'US',
    value: 'VT',
    label: 'Vermont',
  },
  {
    code: 'US',
    value: 'WA',
    label: 'Washington',
  },
  {
    code: 'US',
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    code: 'US',
    value: 'WV',
    label: 'West Virginia',
  },
  {
    code: 'US',
    value: 'WY',
    label: 'Wyoming',
  },
];

export enum ClaimStatus { InProgress = 'IN PROGRESS' }

export enum ClaimOnlineStatus { Synched = 'SYNCHED', ToBeSynched = 'TO BE SYNCHED', AvailableOffline = 'AVAILABLE OFFLINE' }

export const OVERRIDE_STRINGS = (t: TFunction<'translation', undefined>) => ({
  'allItemsAreSelected': t('allItemsAreSelected').toString(),
  'noOptions': t('noOptions').toString(),
  'search': t('search').toString(),
  'selectAll': t('selectAll').toString(),
  'selectAllFiltered': t('selectAllFiltered').toString(),
  'selectSomeItems': t('select').toString(),
});

export enum ApiStatus { SUCCESS = 'SUCCESS', FAILURE = 'FAILURE' }

export enum PhaseStatus {
  New = 1,
  Quoted = 2,
  Authorized = 3,
  Complete = 4,
  Cancelled = 5,
  NewA = 6,
  QuotedA = 7,
}

export enum ClaimType {
  Residential = 1,
  Commercial = 2,
}

export enum FileType {
  Image = 'Image',
  Document = 'Doc',
}

export enum ExcludedDocType {
  Image = 'Image',
  PurchaseOrder = 'PO',
  Invoice = 'Invoice',
  SiteInspection = 'ISI',
}

export enum Region {
  AllOrNotApplicable = 0,
  Ontario = 1,
  Prairies = 2,
  BritishColumbia = 3,
  Quebec = 4,
  Atlantic = 5,
  Alberta = 7,
  LargeLoss = 21,
}

export enum EquipmentStatus {
  Available = 1,
  IssuedToJob = 2,
  Missing = 3,
  BeingRepaired = 4,
  NoLongerInService = 5,
  InterBranchTransit = 6,
  InTransit = 7,
  JobsiteIdle = 8,
}

export const NONE = 'none';

export const OTHER_VERTICAL = 1;

export enum ProfileActiveOption { Badge = 'Badge', Details = 'Details', Settings = 'Settings', Expenses = 'Expenses' }

export const ENCIRCLE_CLAIM_ROOT_URL = 'https://encircleapp.com/app/property-claims/claim';

export enum MessageDirection { Outgoing = 'OUTGOING', Incoming = 'INCOMING' }

export const APP_VERSION = '1.4.6';