import { useParams } from 'react-router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useAuth } from '../hooks/authContext';
import { ClaimDetailsActiveOption, ClaimDetailsMenuTabs } from './helper/ClaimDetailsMenuHelper';
import useAdjusters from '../hooks/adjusters';
import useInsuranceCompanies from '../hooks/insuranceCompanies';
import useProjectManagers from '../hooks/projectManagers';
import useStorage from '../hooks/storage';
import { NONE } from './helper/Const';
import { ContactsListType } from './helper/ContactsListType';
import { ProjectManagersType } from './helper/ProjectManagersType';
import { Option } from '../utils/Interfaces';
import { getClaimStorage } from './helper/ClaimHelper';
import useHandleJobContactsCall from '../hooks/handleJobContactsCall';
import useHandleJobLocationMap from '../hooks/handleJobLocationMap';
import useClaimDocuments from '../hooks/useClaimDocuments';
import useClaimNotes from '../hooks/useClaimNotes';
import useClaimNotesDepartments from '../hooks/useClaimNotesDepartments';
import { Phase } from '../types/Phases';
import { storeClaimDetails } from './helper/offlineHelper/ClaimDetailsHelper';
import useLossTypes from '../hooks/lossTypes';
import usePropertyManagers from '../hooks/propertyManagers';
import useClaimDetails from '../hooks/useClaimDetails';
import { useClaimOffline } from '../contexts/ClaimOfflineContext';
import NetworkContext from '../contexts/NetworkContext';
import useRegionList from '../hooks/regionsPerUser';
import checkIfRegionIsGCR from './helper/GCRcheck';

interface RouteParams {
  jobIdx: string,
}
const ClaimDetailViewModel = () => {
  const YEAR_TBD = 'TBD';
  const { user, userState } = useAuth()!;
  const { jobIdx } = useParams<RouteParams>();
  const [activeClaimDetailOption, setActiveClaimDetailOption] = useState<ClaimDetailsActiveOption>(ClaimDetailsMenuTabs.ClaimInfo);
  const [contactsList, setContactsList] = useState<ContactsListType[]>([]);
  const [claim, setClaim] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { offlineClaims : offlineClaimStorage, refresh }  = useClaimOffline()!;
  const offlineClaim = useMemo(() => offlineClaimStorage || [], [offlineClaimStorage]);
  const localJobIdx: number = jobIdx ? Number(jobIdx) : 0;
  const network = useContext(NetworkContext);
  const localJobIdxString: string = jobIdx || '0';
  const [refetchPhotos, setRefetchPhotos] = useState<number>(0);
  const region = userState.userAuthPayload?.regionId!;
  // initialize storage
  const storage = useStorage();

  const projectManagers = useProjectManagers(region, userState, storage);
  const projectManagerOptions: Option[] = projectManagers.map((pm: ProjectManagersType) => ({ label: pm.value, value: pm.id }));

  const propertyManagers = usePropertyManagers(region, userState, storage);
  const propertyManagerOptions = propertyManagers.map(manager => ({ label: `${manager.firstName} ${manager.lastName}`, value: manager.adjCode }));

  // load adjusters company list
  const adjusters = useAdjusters(region, userState, storage);
  const adjusterOptions = adjusters.map(adjuster => ({ label: `${adjuster.firstName} ${adjuster.lastName}`, value: adjuster.adjCode }));

  // load insurance company list
  const insurers = useInsuranceCompanies(region, userState, storage);
  const insuranceCompaniesOptions = insurers.map(insuranceCompanie => ({ label: insuranceCompanie.company, value: insuranceCompanie.coCode }));

  const lossTypes = useLossTypes(region, userState, storage);
  const lossTypeOptions = lossTypes.map(lossType => ({ label: lossType.lossCategory, value: lossType.lossCategory }));

  // onSuccess: use the data, then store the data
  const onClaimDetailsSuccess = async (_data : any) => {
    if (_data) {
      // use data
      _data.claim.lossDate = _data.claim?.lossDate?.replace('Z', '') || null;
      _data.claim.jobDateOpen = _data.claim?.jobDateOpen?.replace('Z', '') || null;
      _data.claim.jobDOpen = _data.claim.jobDOpen && _data.claim?.jobDOpen?.replace('Z', '') || null;
      setClaim(_data.claim);
      // store data
      await storeClaimDetails({
        _data,
        user,
        adjusterOptions,
        insuranceCompaniesOptions,
        lossTypeOptions,
        propertyManagerOptions,
        projectManagerOptions,
        region,
      });
      refresh();
    }
  };

  const regionId = region;
  const { isLoading, refetch, isFetching } = useClaimDetails(region, !network.connected ? 0 : Number(jobIdx), userState, onClaimDetailsSuccess);

  useEffect(() => {
    if (!network.connected && !claim) {
      const getAsync = async () => {
        const { claimForm: localClaim } = await getClaimStorage(jobIdx, offlineClaim, projectManagerOptions, adjusterOptions, insuranceCompaniesOptions);
        setClaim(localClaim);
      };
      getAsync();
    }
  }, [adjusterOptions, claim, insuranceCompaniesOptions, network.connected, jobIdx, offlineClaim, projectManagerOptions]);

  useEffect(() => {
    const openNotification = window.localStorage.getItem('open_notifications');
    if (openNotification && claim && activeClaimDetailOption !== ClaimDetailsMenuTabs.Notes) {
      setActiveClaimDetailOption(ClaimDetailsMenuTabs.Notes);
    }
  }, [activeClaimDetailOption, claim]);

  const {
    claimDocumentsList: claimDocs,
    refetch: refetchDocuments,
    isLoading: isLoadingDocuments,
  } = useClaimDocuments(regionId, localJobIdx, userState);
  const {
    claimNotesList: claimNotes,
    refetch: refetchNotes,
    isLoading: isLoadingNotes,
  } = useClaimNotes(regionId, localJobIdx, userState);

  const [phases, setPhases] = useState<Array<Phase>>([]);
  
  const notesCategories = useClaimNotesDepartments(regionId, userState, storage);

  const [activePhase, setActivePhase] = useState<Phase | null>(null);
  const statusDesc = claim?.phases?.statusDesc || 'OK';
  const handleJobContactsCall = useHandleJobContactsCall();
  const handleJobLocationMap = useHandleJobLocationMap();

  const regions = useRegionList(userState, storage);
  const isRegionGCR = checkIfRegionIsGCR(region, regions);

  useEffect(() => {
    if (claim?.phases?.length !== phases?.length) {
      setPhases(claim?.phases as Array<Phase>);
    }
    if (claim && !activePhase) {
      setActivePhase(claim?.phases[0]);
      setContactsList([{ role: isRegionGCR ? t('fieldEstimator').toString() : t('projectManager').toString(), name: claim?.pmNameClaim, email: claim?.pmEmail }]);
    }
  }, [claim, activePhase, phases?.length, isRegionGCR]);

  const setActivePhaseCB = (phaseIndex: number) => {
    const foundPhase = phases.find((phase) => phase.phaseIndx === phaseIndex);
    if (foundPhase) {
      setActivePhase(foundPhase);
    }
  };

  const handleCallButtonClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    handleJobContactsCall({
      businessContact: claim?.contactName,
      projectName: claim?.projName,
      projectPhone1: claim?.projPh1 || claim?.projPhone1,
      projectPhone2: claim?.projPh2 || claim?.projPhone2,
      projectPhone3: claim?.projPh3 || claim?.projPhone3,
      businessContactPhone: claim.contactPhone || claim.projPh1 || claim.projPhone1,
      claimIndx: localJobIdx,
      setOpenModal,
    });
  };

  const handleMapButtonClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    handleJobLocationMap({
      lossAddressLat: claim?.lossAddressLat,
      lossAddressLon: claim?.lossAddressLon,
      projAddr: claim?.projAddr,
    });
  };

  const handleYearOfConstruction = (): string => {
    const yearOfConstruction = claim?.yearOfConstruction;
    if (!yearOfConstruction || yearOfConstruction === YEAR_TBD || yearOfConstruction === '?' || yearOfConstruction === 'N/A') {
      // returning 'none' cause the query param cannot be empty.
      return NONE;
    }
    return claim?.yearOfConstruction;
  };

  const changeActiveClaimDetailOption = (tab: ClaimDetailsActiveOption) => {
    switch (tab) {
      case ClaimDetailsMenuTabs.Documents:
        refetchDocuments();
        break;
      case ClaimDetailsMenuTabs.Notes:
        refetchNotes();
        break;
      case ClaimDetailsMenuTabs.Photos:
        setRefetchPhotos(prev => prev + 1);
        break;
      default:
        refetch();
        break;
    }
    setActiveClaimDetailOption(tab);
  };

  return {
    claim,
    region,
    activePhase,
    isLoading,
    isFetching,
    isOffline: !network.connected ? 'true' : '',
    handleYearOfConstruction,
    activeClaimDetailOption,
    changeActiveClaimDetailOption,
    refetchPhotos,
    user,
    claimDocs,
    refetchDocuments,
    isLoadingDocuments,
    localJobIdxString,
    localJobIdx,
    claimNotes,
    refetchNotes,
    isLoadingNotes,
    phases,
    notesCategories,
    statusDesc,
    contactsList,
    refetch,
    setActivePhaseCB,
    handleCallButtonClick,
    handleMapButtonClick,
    openModal,
    setOpenModal,
    claimNumber: localJobIdxString,
  };
};

export default ClaimDetailViewModel;