import { RegionsType } from '../../graphql/GetRegionsList';

const checkIfRegionIsGCR = (regionId: number, regionList: RegionsType[]) => {
  const region = regionList.find(r => r.regionId === regionId);
  if (region?.regionName === 'Great Canadian Roofing') {
    return true;
  }
  return false;
};

export default checkIfRegionIsGCR;