import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close } from 'ionicons/icons';
import ReactQuill from 'react-quill';
import Moment from 'moment';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import './CertificateOfCompletion.css';
import FosCertificateSelectItem from '../atom/FosCertificateSelectItem';
import openDocumentURL from '../pages/helper/Documents';
import useFormViewModel, { PrintFormProps } from './FormViewModel';
import FosLabelCheckbox from '../atom/FosLabelCheckbox';
import { Template as TemplateEnum } from '../utils/FormTemplate';
import useStorage from '../hooks/storage';
import useRegionList from '../hooks/regionsPerUser';
import checkIfRegionIsGCR from '../pages/helper/GCRcheck';

type FormData = {
  ownerEmail?: string;
  ownerName?: string;
  owner2Email?: string;
  owner2Name?: string;
  customerComments?: string;
  fosComments?: string;
  isRebuild?: boolean;
  language?: string;
  workCompleteDate?: Date;
};

const CertificateOfCompletionForm: React.FC<PrintFormProps> = ({ isOpen, claimIndx, onDismiss, region, onSubmit }) => {
  const { t } = useTranslation();
  const {     validateField,
    validateEmail,
    isLoading,
    setIsLoading,
    isEmailAlertOpen,
    setIsEmailAlertOpen,
    isEmail2AlertOpen,
    setIsEmail2AlertOpen,
    isName2AlertOpen,
    setIsName2AlertOpen,
    isNameAlertOpen,
    setIsNameAlertOpen,
    isPhaseAlertOpen,
    setIsPhaseAlertOpen,
    isLanguageAlertOpen,
    setIsLanguageAlertOpen,
    selectedTemplate,
    setSelectedTemplate,
    isDateAlertOpen,
    setIsDateAlertOpen,
    isSecondSigner,
    setIsSecondSigner } = useFormViewModel();

  const { userState } = useAuth()!;
  const restClient = getRestClient(userState, ClientType.FORM);
  const { user } = useAuth()!;
  const storage = useStorage();
  const regions = useRegionList(userState, storage);
  const isRegionGCR = checkIfRegionIsGCR(region, regions);

  const templateOptions = isRegionGCR ? [
    { label: t('standard'), value: TemplateEnum.Standard },
  ] : [
    { label: t('standard'), value: TemplateEnum.Standard },
    { label: t('TD'), value: TemplateEnum.TD },
  ];

  const [formData, setFormData] = useState<FormData>({
    ownerEmail: undefined,
    ownerName: undefined,
    owner2Email: undefined,
    owner2Name: undefined,
    customerComments: undefined,
    fosComments: undefined,
    isRebuild: undefined,
    language: undefined,
    workCompleteDate: undefined,
  });

  function validateForm(data: FormData) {
    let check = true;

    check = validateEmail(setIsEmailAlertOpen, data.ownerEmail) === false ? false : check;
    check = validateField(data.ownerName === undefined, setIsNameAlertOpen) === false ? false : check;
    check = validateField(data.isRebuild === undefined, setIsPhaseAlertOpen) === false ? false : check;
    check = validateField(data.language === undefined, setIsLanguageAlertOpen) === false ? false : check;

    return check;
  }

  function validateTDForm(data: FormData) {
    let check = true;

    check = validateEmail(setIsEmailAlertOpen, data.ownerEmail) === false ? false : check;
    if (isSecondSigner) {
      check = validateEmail(setIsEmail2AlertOpen, data.owner2Email) === false ? false : check;
    }
    check = validateField(data.ownerName === undefined, setIsNameAlertOpen) === false ? false : check;
    check = validateField(data.language === undefined, setIsLanguageAlertOpen) === false ? false : check;
    if (isSecondSigner) {
      check = validateField(data.owner2Name === undefined, setIsName2AlertOpen) === false ? false : check;
    }
    check = validateField(data.workCompleteDate === undefined, setIsDateAlertOpen) === false ? false : check;
    return check;
  }
  // let agreementId = '';
  const onClickSignNowHandler = async () => {
    if (validateForm(formData)) {
      setIsLoading(true);
      try {
        restClient
          .post('/common/completioncertificate/report', {
            regionId: region,
            claimIndx,
            ownerEmail: formData.ownerEmail,
            ownerName: formData.ownerName,
            owner2Email: formData.owner2Email,
            owner2Name: formData.owner2Name,
            customerComments: formData.customerComments,
            fosComments: formData.fosComments,
            isRebuild: formData.isRebuild,
            language: formData.language,
            fosName: '',
            isTd: false,
            fosEmail: userState.userAuthPayload?.preferredUsername,
            secondSigner: false,
            workCompleteDate: Moment(formData.workCompleteDate).format('MMM DD, YYYY'),
          })
          .then((res) => {
            openDocumentURL(res.data.signingUrl);
            // agreementId = res.data.agreementId;
          })
          .then(() => {
            setIsLoading(false);
            onSubmit(true);
          })
          .catch((ex) => {
            const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
            setIsLoading(false);
            onSubmit(false, msg);
          });
      } catch {
        setIsLoading(false);
        onSubmit(false);
      }
    }
  };
  const isDate = () => {
    if (formData.workCompleteDate === undefined) {
      return true;
    }
    return false;
  };
  const getDateValueString = () => {
    if (formData.workCompleteDate !== undefined) {
      return Moment(formData.workCompleteDate).format('DD MMMM, YYYY');
    }
    return undefined;
  };
  const onClickSignNowHandlerTD = async () => {
    if (validateTDForm(formData)) {
      setIsLoading(true);
      try {
        restClient
          .post('/common/completioncertificate/report', {
            regionId: region,
            claimIndx,
            ownerEmail: formData.ownerEmail,
            ownerName: formData.ownerName,
            owner2Email: formData.owner2Email,
            owner2Name: formData.owner2Name,
            fosEmail: userState.userAuthPayload?.preferredUsername,
            fosName: user?.fullName ?? '',
            language: formData.language,
            isTd: true,
            secondSigner: isSecondSigner,
            workCompleteDate: Moment(formData.workCompleteDate).format('MMM DD, YYYY'),
          })
          .then((res) => {
            openDocumentURL(res.data.signingUrl);
            // agreementId = res.data.agreementId;
          })
          .then(() => {
            setIsLoading(false);
            onSubmit(true);
          })
          .catch((ex) => {
            const msg = ex?.response?.data?.ErrorCode === 400 ? t('errorXSS') : '';
            setIsLoading(false);
            onSubmit(false, msg);
          });
      } catch {
        setIsLoading(false);
        onSubmit(false);
      }
    }
  };
  if (selectedTemplate === TemplateEnum.TD) {
    return (
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar class="header">
            <IonTitle>{t('certificateOfCompletion').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismiss()}>
                <div className="close-icon-container">
                  <IonIcon className="tools-close-button" icon={close} />
                </div>{' '}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <FosCertificateSelectItem
            altBorder
            label={t('template')}
            options={templateOptions}
            onChange={(newValue) => {
              setSelectedTemplate(newValue.detail.value);
            }}
            placeholder={t('standard')}
          />
          <div className="certificate-of-completion-section row">
            <IonLabel className="text-grey">{t('policyHolderName').toString()}*</IonLabel>
            <IonTextarea
              name="ownerName"
              className="work-auth-border-color"
              autoGrow
              required
              value={formData.ownerName}
              onIonChange={(ev) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    ownerName: ev.detail.value!.toString(),
                  };
                  setIsNameAlertOpen(newData.ownerName === undefined);
                  return newData;
                });
              }}
            />
            {isNameAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAPolicyHolderName').toString()}</IonText>
            )}
          </div>
          <div className="certificate-of-completion-section row">
            <IonLabel className="text-grey">{t('policyHolderEmail').toString()}*</IonLabel>
            <IonTextarea
              name="ownerEmail"
              className="work-auth-border-color"
              autoGrow
              required
              value={formData.ownerEmail}
              onIonChange={(evt) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    ownerEmail: evt.detail.value!.toString(),
                  };
                  validateEmail(setIsEmailAlertOpen, newData.ownerEmail);
                  return newData;
                });
              }}
            />
            {isEmailAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
            )}
          </div>

          <div>
            <FosLabelCheckbox
              label={t('addSecondPolicyHolder').toString()}
              onValueChange={() => {
                if (isSecondSigner) {
                  setIsSecondSigner(false);
                  setFormData({ ...formData, owner2Email: undefined, owner2Name: undefined });
                  setIsEmail2AlertOpen(false);
                  setIsName2AlertOpen(false);
                } else {
                  setIsSecondSigner(true);
                }
              }}
              value={!!isSecondSigner}
              checked={!!isSecondSigner}
            />
          </div>
          <div className="certificate-of-completion-section row" hidden={!isSecondSigner}>
            <IonLabel className="text-grey">{t('secondPolicyHolderName').toString()}*</IonLabel>
            <IonTextarea
              name="ownerName"
              className="work-auth-border-color"
              autoGrow
              required
              value={formData.owner2Name}
              onIonChange={(ev) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    owner2Name: ev.detail.value!.toString(),
                  };
                  setIsName2AlertOpen(newData.owner2Name === undefined);
                  return newData;
                });
              }}
            />
            {isName2AlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAPolicyHolderName').toString()}</IonText>
            )}
          </div>
          <div className="certificate-of-completion-section row" hidden={!isSecondSigner}>
            <IonLabel className="text-grey">{t('secondPolicyHolderEmail').toString()}*</IonLabel>
            <IonTextarea
              name="ownerEmail"
              className="work-auth-border-color text-grey"
              autoGrow
              required
              value={formData.owner2Email}
              onIonChange={(evt) => {
                setFormData((prevData) => {
                  const newData = {
                    ...prevData,
                    owner2Email: evt.detail.value!.toString(),
                  };
                  validateEmail(setIsEmail2AlertOpen, newData.owner2Email);
                  return newData;
                });
              }}
            />
            {isEmail2AlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
            )}
          </div>
          <div className="ion-margin-bottom">
            <IonItem className="work-auth-border-color fos-label-value-item-style" id="date-trigger">
              <div className="label-container" />
              <IonLabel className="fos-certificate-select-label-coc">
                <IonText class="text-grey">{t('workCompleteDate').toString()}*</IonText>
              </IonLabel>
              <IonText hidden={isDate()} className="wa-select-date-label">
                {getDateValueString()}
              </IonText>
              <IonModal trigger="date-trigger" className="center fixed-300-width" keepContentsMounted>
                <IonDatetime
                  showDefaultButtons
                  id="workCompleteDate"
                  presentation="date"
                  name="workCompleteDate"
                  value={formData.workCompleteDate?.toISOString()}
                  onIonChange={(newValue) => {
                    if (typeof newValue.detail.value === 'string' && newValue.detail.value !== undefined) {
                      setFormData({
                        ...formData,
                        workCompleteDate: new Date(Date.parse(newValue.detail.value)),
                      });
                      setIsDateAlertOpen(false);
                    }
                    if (newValue.detail.value === undefined) {
                      setIsDateAlertOpen(true);
                    }
                  }}
                />
              </IonModal>
            </IonItem>
            {isDateAlertOpen && (
              <IonText className="invalid-entry-message">{t('pleaseSelectWorkCompleteDate').toString()}</IonText>
            )}
          </div>
          <FosCertificateSelectItem
            altBorder
            disableMarginBottom
            asterisk
            label={t('language')}
            options={[
              { label: t('english'), value: 'english' },
              // TODO: ADD WHEN FR PDF AVAILABLE { label: t('french'), value: 'french' },
            ]}
            onChange={(newValue) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  language: newValue.detail.value,
                };
                setIsLanguageAlertOpen(newData.language === undefined);
                return newData;
              });
            }}
          />
          {isLanguageAlertOpen && (
            <IonText className="invalid-entry-message margin-bottom-adjust">
              {t('pleaseSelectLanguage').toString()}
            </IonText>
          )}
          <IonFooter class="add-padding">
            <IonButton
              class=" button-fill-margin"
              fill="solid"
              expand="block"
              onClick={() => onClickSignNowHandlerTD()}
            >
              {t('signNow').toString()}
            </IonButton>
          </IonFooter>
          <IonLoading isOpen={isLoading} message={t('loadingForm')} />
        </IonContent>
      </IonModal>
    );
  }
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar class="header">
          <IonTitle>{t('certificateOfCompletion').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <div className="close-icon-container">
                <IonIcon className="tools-close-button" icon={close} />
              </div>{' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <FosCertificateSelectItem
          altBorder
          label={t('template')}
          options={templateOptions}
          onChange={(newValue) => {
            setSelectedTemplate(newValue.detail.value);
          }}
          placeholder={t('standard')}
        />
        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('customerComments').toString()}</IonLabel>
          <ReactQuill
            className="customer-comments"
            value={formData.customerComments}
            onChange={(e) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  customerComments: e,
                };
                return newData;
              });
            }}
          />
        </div>
        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{isRegionGCR ? t('gcrComments').toString() : t('fosComments').toString()}</IonLabel>
          <ReactQuill
            className="customer-comments"
            value={formData.fosComments}
            onChange={(e) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  fosComments: e,
                };
                return newData;
              });
            }}
          />
        </div>
        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerName').toString()}*</IonLabel>
          <IonTextarea
            name="ownerName"
            className="owner-info"
            autoGrow
            required
            value={formData.ownerName}
            onIonChange={(ev) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerName: ev.detail.value!.toString(),
                };
                setIsNameAlertOpen(newData.ownerName === undefined);
                return newData;
              });
            }}
          />
          {isNameAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAnOwnerName').toString()}</IonText>
          )}
        </div>
        <div className="certificate-of-completion-section row">
          <IonLabel className="select-label">{t('ownerEmail').toString()}*</IonLabel>
          <IonTextarea
            name="ownerEmail"
            className="owner-info"
            autoGrow
            required
            value={formData.ownerEmail}
            onIonChange={(evt) => {
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  ownerEmail: evt.detail.value!.toString(),
                };
                validateEmail(setIsEmailAlertOpen, newData.ownerEmail);
                return newData;
              });
            }}
          />
          {isEmailAlertOpen && (
            <IonText className="invalid-entry-message">{t('pleaseEnterAValidEmailAddress').toString()}</IonText>
          )}
        </div>
        <FosCertificateSelectItem
          altBorder
          label={`${t('phase')}`}
          name={`${t('phase')}`}
          asterisk
          options={[
            { label: t('rebuild'), value: 'rebuild' },
            { label: t('emergency'), value: 'emergency' },
          ]}
          disableMarginBottom={isPhaseAlertOpen}
          onChange={(newValue) => {
            if (newValue.detail.value === 'rebuild') {
              setFormData({ ...formData, isRebuild: true });
            } else {
              setFormData({ ...formData, isRebuild: false });
            }
            if (newValue.detail.value !== undefined) {
              setIsPhaseAlertOpen(false);
            }
          }}
        />
        {isPhaseAlertOpen && (
          <IonText className="invalid-entry-message margin-bottom-adjust">{t('pleaseSelectPhase').toString()}</IonText>
        )}
        <FosCertificateSelectItem
          altBorder
          disableMarginBottom={isLanguageAlertOpen}
          asterisk
          label={t('language')}
          options={[
            { label: t('english'), value: 'english' },
            { label: t('french'), value: 'french' },
          ]}
          onChange={(newValue) => {
            setFormData((prevData) => {
              const newData = {
                ...prevData,
                language: newValue.detail.value,
              };
              setIsLanguageAlertOpen(newData.language === undefined);
              return newData;
            });
          }}
        />
        {isLanguageAlertOpen && (
          <IonText className="invalid-entry-message margin-bottom-adjust">
            {t('pleaseSelectLanguage').toString()}
          </IonText>
        )}
        <IonFooter>
          <IonButton class=" button-fill-margin" fill="solid" expand="block" onClick={() => onClickSignNowHandler()}>
            {t('signNow').toString()}
          </IonButton>
        </IonFooter>
        <IonLoading isOpen={isLoading} message={t('sendingForm')} />
      </IonContent>
    </IonModal>
  );
};
export default CertificateOfCompletionForm;
