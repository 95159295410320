import { IonButton, IonPage, IonList } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AddPhaseForm.css';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FosSelectItem from '../atom/FosSelectItem';
import StackedInput from '../atom/StackedInput';
import useProjectManagers from '../hooks/projectManagers';
import { useAuth } from '../hooks/authContext';
import usePhaseCategories from '../hooks/phaseCategories';
import useStorage from '../hooks/storage';
import { XactModel } from '../graphql/GetJobsList';
import FosCurrencyInput from '../components/FosCurrencyInput';
import FosSelectBigListItem from '../atom/FosSelectBigListItem';
import useRegionList from '../hooks/regionsPerUser';
import checkIfRegionIsGCR from '../pages/helper/GCRcheck';

type FormData = {
  id: number;
  estValue: number;
  description: string;
  projectManager?: string;
  projectManagerId: string;
  phase?: string;
  phaseId: string;
  phaseCode: string;
  xactTransactionId?: string;
};

type Props = {
  onChange: (value: FormData) => void;
  phaseModalOpen: (value: boolean) => void;
  preSelectedPhase: string;
  addPhaseLabel: string;
  prePopulatedPhase?: XactModel;
};

const AddPhaseForm: React.FC<Props> = ({ onChange, phaseModalOpen, preSelectedPhase, addPhaseLabel, prePopulatedPhase }) => {
  const { t } = useTranslation();
  const { userState } = useAuth()!;

  const storage = useStorage();

  const regionId = userState.userAuthPayload?.regionId!;
  const projectManagers = useProjectManagers(regionId, userState, storage);
  const projectManagerOptions = projectManagers.map(manager => ({ label: manager.value, value: manager.id }));

  const phaseCategories = usePhaseCategories(regionId, userState, storage);
  const phaseCategoriesOptions = phaseCategories.map(phase => ({ label: phase.value, value: phase.id }));

  const region = userState.userAuthPayload?.regionId!;
  const regions = useRegionList(userState, storage);
  const isRegionGCR = checkIfRegionIsGCR(region, regions);

  const schema = yup.object({
    description: yup
      .string()
      .max(60, t('projectNameMaxLengthIs60')),
    estValue: yup.number().required(t('phaseEstSaleIsRequired')).transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(true),
    projectManagerId: yup
      .string()
      .required(isRegionGCR ? t('fieldEstimatorIsRequired') : t('projectManagerIsRequired')),
    phaseId: yup
      .string()
      .required(t('phaseIdIsRequired')),
  });

  const { handleSubmit, register, control, setValue, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (preSelectedPhase) {
      setValue('phaseId', preSelectedPhase);
    }
  }, [preSelectedPhase, setValue]);

  const onSubmit = handleSubmit((data: FormData) => {
    onChange({
      id: Date.now(),
      projectManager: projectManagerOptions.find(pm => pm.value === data.projectManagerId)?.label,
      projectManagerId: data.projectManagerId,
      description: data.description,
      phase: phaseCategoriesOptions.find(phase => phase.value === data.phaseId)?.label,
      phaseId: data.phaseId,
      phaseCode: data.phaseCode,
      estValue: data.estValue,
      xactTransactionId: prePopulatedPhase?.xactTransactionId || '',
    });
    phaseModalOpen(false);
  });

  return (
    <form id="addPhaseForm" className="fos-form" onSubmit={onSubmit}>
      <IonPage>
        <IonList>
          <FosSelectItem
            label={t('phase')}
            required
            options={phaseCategoriesOptions}
            placeholder={preSelectedPhase}
            error={errors.phaseId?.message}
            {...register('phaseId')}
          />
          <StackedInput
            label={t('description')}
            {...register('description')}
            error={errors.description?.message}
          />
          <Controller
            control={control}
            name="estValue"
            render={({ field: { value, onChange } }) => (
              <FosCurrencyInput
                required
                value={value?.toString()}
                label={`${t('estSale')} *`}
                onChange={onChange}
                error={errors.estValue?.message} />
            )} />
          <div className="phase-form-item">
            <Controller
              control={control}
              name="projectManagerId"
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <FosSelectBigListItem
                  id="selectProjectManager"
                  required
                  onChange={onChange}
                  value={value}
                  name={name}
                  label={isRegionGCR ? t('fieldEstimator').toString() : t('projectManager').toString()}
                  options={projectManagerOptions}
                  error={error?.message} />
              )} />
          </div>
        </IonList>
      </IonPage>
      <IonButton className="phase-form-button" fill="solid" onClick={onSubmit}>
        {addPhaseLabel}
      </IonButton>
    </form>
  );
};

export default AddPhaseForm;
