import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonNote,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import * as yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { close } from 'ionicons/icons';

import { ReactComponent as BackArrow } from '../assets/icons/ic-back-arrow.svg';
import { useAuth } from '../hooks/authContext';
import { Phase } from '../types/Phases';
import './AddPhotoToClaimForm.css';
import { ClaimPhotosType } from '../graphql/GetClaimPhotosList';
import AddLevels from './AddLevels';
import { RoomModelType } from '../graphql/GetLevelsList';
import getGraphQLClient from '../hooks/graphQLClientUtil';
import UPDATE_PHOTOS_ROOM from '../graphql/UpdatePhotosRoom';
import { ApiStatus } from '../pages/helper/Const';
import { Option } from '../utils/Interfaces';
import FosSelectItem from '../atom/FosSelectItem';
import FosCachedImage from '../components/FosCachedImage';
import { AddPhotosType, usePhotoUpload } from '../contexts/PhotoUploadContext';
import NetworkContext from '../contexts/NetworkContext';
import { saveOfflineClaimPhoto, useSavePhotoMutation } from '../pages/helper/offlineHelper/ClaimPhotosHelper';
import { usePermissions, PermissionsList } from '../hooks/permissions';
import { getErrorMsg } from '../pages/helper/AppHelper';
import getRestClient, { ClientType } from '../utils/AxiosClient';

const closeIcon = 'assets/images/icons/ic_close.png';

type Props = {
  isOpen: boolean;
  claimPhotos: {
    blobArray: File[];
  };
  claimIndex: number;
  region: number;
  phases: Phase[];
  editClaimPhoto?: ClaimPhotosType | null;
  selectedRoomId?: number;
  onDismiss: () => void;
  onSubmit: (success: boolean, msg?: string) => void;
};

type FormData = {
  description: string;
  phaseId: string;
};

const tempRoomImageURL = 'assets/images/placeholder.png';

interface MutationParams {
  roomId?: number;
  ctFileIds: number[];
}

const AddPhotoToClaimForm: React.FC<Props> = ({
  isOpen,
  claimPhotos,
  region,
  claimIndex,
  phases,
  editClaimPhoto,
  selectedRoomId,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const photoUploadcontext = usePhotoUpload()!;

  const { userState, user, checkTokenExpiration } = useAuth()!;
  const [filePath, setFilePath] = useState<string>();
  let selectedRoom: number | undefined;
  const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);
  const [isShowInISIChecked, setIsShowInISIChecked] = useState(false);
  const [isXactimateChecked, setIsXactimateChecked] = useState(false);
  const [isSymbilityChecked, setIsSymbilityChecked] = useState(false);
  const [isTimeStampChecked, setIsTimeStampChecked] = useState(true);
  const [phaseErrorMsg, setPhaseErrorMsg] = useState('');
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState('');
  const phaseOptions: Option[] = phases.map((phase) => ({ label: phase.phaseCode, value: phase.phaseCode }));
  const [isXactBound, setIsXactBound] = useState(false);
  const [isSymbilityBound, setIsSymbilityBound] = useState(false);
  const network = useContext(NetworkContext);
  const { permissions } = usePermissions();
  const [isSaving, setIsSaving] = useState(false);
  const isSendToXAEnabled = permissions.includes(PermissionsList.AllowSendToXA);
  const { mutate } = useMutation({
    mutationFn: (mutationParams: MutationParams) => getGraphQLClient(userState).request(UPDATE_PHOTOS_ROOM, mutationParams),
  });

  const isVideo = ['video/quicktime', 'video/mp4'].includes(claimPhotos?.blobArray?.[0]?.type) || editClaimPhoto?.fileTypeCd === 'Video';

  const schema = yup.object({
    phaseId: yup
      .string()
      .required(t('phaseSelectionRequired')),
    description: yup
      .string(),
  });

  const { handleSubmit, register, setValue, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { phaseId, description } = watch();

  useEffect(() => {
    if (phaseId && phaseErrorMsg) {
      setPhaseErrorMsg('');
    }
  }, [phaseErrorMsg, phaseId]);

  useEffect(() => {
    if (description && descriptionErrorMsg || (!isXactimateChecked && !isSymbilityChecked)) {
      setDescriptionErrorMsg('');
    }
  }, [description, descriptionErrorMsg, isXactimateChecked, isSymbilityChecked]);

  useEffect(() => {
    if (editClaimPhoto) {
      if (!editClaimPhoto.phase && editClaimPhoto.phaseIndx) {
        const phaseCode = phases.find(p => p.phaseIndx === Number(editClaimPhoto.phaseIndx))?.phaseCode;
        setValue('phaseId', phaseCode || '');
      } else {
        setValue('phaseId', editClaimPhoto.phase || '');
      }
      setValue('description', editClaimPhoto.description || '');
      setIsShowInISIChecked(editClaimPhoto.includedInIsi);
      setIsXactimateChecked(editClaimPhoto.xactimate || false);
      setIsSymbilityChecked(editClaimPhoto.isSymbilityDoc || false);
      setFilePath(editClaimPhoto.filePath || editClaimPhoto.thumbnailFileName);
    } else {
      setValue('phaseId', '');
      setValue('description', '');
      setIsXactimateChecked(false);
      setIsShowInISIChecked(false);
      setFilePath('');
    }
  }, [editClaimPhoto, setValue, phases]);

  useEffect(() => {
    const phase = phases.find(f => f.phaseCode === phaseId);
    const newSendToXAEnabled = !!phase?.linkedToXA && isSendToXAEnabled && !isVideo;
    const newSendToSymbilityEnabled = !!phase?.linkedToSymbility && !isVideo;
    setIsXactBound(newSendToXAEnabled);
    setIsSymbilityBound(newSendToSymbilityEnabled);
    if (!newSendToXAEnabled) {
      setIsXactimateChecked(false);
    }

    if (!newSendToSymbilityEnabled) {
      setIsSymbilityBound(false);
    }

  }, [isSendToXAEnabled, isVideo, phaseId, phases]);

  // clean form after close
  useEffect(() => {
    if (!isOpen) {
      setValue('phaseId', '');
      setValue('description', '');
      setIsXactimateChecked(false);
      setIsSymbilityChecked(false);
      setIsShowInISIChecked(false);
      setIsTimeStampChecked(true);
      setFilePath('');
      setPhaseErrorMsg('');
      setDescriptionErrorMsg('');
    }
  }, [isOpen, setValue]);

  const onImgError = (event: any) => {
    const img = event.target;
    img.src = tempRoomImageURL;
  };

  const onSubmitHandler = handleSubmit(async (data: FormData) => {
    if (!phaseId) {
      setPhaseErrorMsg(t('phaseSelectionRequired'));
      return;
    }
    if ((isXactimateChecked || isSymbilityChecked) && !description) {
      setDescriptionErrorMsg(t('descriptionRequired'));
      return;
    }

    if (isVideo) {
      try {
        const formData = new FormData();
    
        formData.append('regionId', userState.userAuthPayload?.regionId?.toString() || '');
        formData.append('description', data.description);
        formData.append('fileTypeCd', 'Video');
    
        formData.append('uploadBy', user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '');
        
        claimPhotos.blobArray.forEach(file => {
          formData.append('files', file);
        });
        
        const phase = phases.find(f => f.phaseCode === data.phaseId);
        formData.append('PhaseIndx', phase?.phaseIndx?.toString() || '');
  
        setIsSaving(true);
        const restClient = getRestClient(userState, ClientType.PLAIN);
        if (editClaimPhoto) {
          await restClient.post(`/common/claims/${claimIndex}/documents/${editClaimPhoto?.ctfileId}`, formData);
        } else {
          await restClient.post(`/common/claims/${claimIndex}/documents`, formData);
        }
        setIsSaving(false);
        onSubmit(true);
      } catch (e) {
        window.console.log(e);
        onSubmit(false);
      }
    } else {
      const photosToUpload = claimPhotos.blobArray;
  
      try {
        const uploadPhotos: AddPhotosType = {
          regionId: region?.toString(),
          claimIndex: claimIndex.toString(),
          description: data.description,
          roomId: selectedRoom || selectedRoomId || undefined,
          includedInIsi: isShowInISIChecked ? 'true' : undefined,
          includeTimestamp: isTimeStampChecked ? 'true' : undefined,
          uploadBy: user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '',
          sendToXact: isXactimateChecked ? 'true' : undefined,
          sendToSymbility: isSymbilityChecked ? 'true' : undefined,
          phaseIndx: data.phaseId ? phases?.find(f => f.phaseCode === data.phaseId)?.phaseIndx.toString() : undefined,
          files: photosToUpload,
          countFail: 0,
        };
  
        photoUploadcontext.addPhotos(uploadPhotos);
        onSubmit(true);
      } catch {
        onSubmit(false);
      }
    }
  });

  /*
  * Mutation to save the form
  * */
  const { isLoading, mutateAsync } = useSavePhotoMutation();

  const onEditHandler = handleSubmit(async (data: FormData) => {
    const request: any = { ...data };
    request.ctfileId = editClaimPhoto?.ctfileId;
    request.includeInIsi = isShowInISIChecked ? 1 : 0;
    request.updatedBy = user?.username;
    const phaseId = data.phaseId ? phases?.find(f => f.phaseCode === data.phaseId)?.phaseIndx.toString() : undefined;
    if (phaseId) {
      request.phaseIndx = Number(phaseId);
    }
    request.sendToXact = isXactimateChecked;
    request.sendToSymbility = isSymbilityChecked;
    delete request.phaseId;

    if (network.connected) {
      await checkTokenExpiration();
      mutateAsync(request)
        .then((response) => {
          if (response?.updateCtPhoto?.status === ApiStatus.SUCCESS) {
            onSubmit(true);
            return;
          }
          throw new Error(response.updateCtPhoto?.message || t('saveSiteInfoError'));
        })
        .catch((e) => {
          const msg = getErrorMsg(e);
          const message = msg || '';
          onSubmit(false, message);
        });
    } else {
      await saveOfflineClaimPhoto({ photo: request, claimIndx: claimIndex });
      onSubmit(true);
    }
  });

  const openLevelsModal = () => {
    setIsLevelsModalOpen(true);
  };

  const onEditNewPhoto = handleSubmit(async (data: FormData) => {
    if (editClaimPhoto) {
      const uploadPhotoEdit: AddPhotosType = {
        regionId: region?.toString(),
        claimIndex: claimIndex.toString(),
        description: data.description,
        roomId: editClaimPhoto.roomId,
        includedInIsi: isShowInISIChecked ? 'true' : undefined,
        includeTimestamp: editClaimPhoto.includedInIsi ? 'true' : undefined,
        uploadBy: user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '',
        sendToXact: isXactimateChecked,
        sendToSymbility: isSymbilityChecked,
        phaseIndx: Number(data.phaseId),
        files: editClaimPhoto.files || [],
        tempId: editClaimPhoto.tempId,
        countFail: 0,
      };
      photoUploadcontext.editPhoto(uploadPhotoEdit);
      onSubmit(true);
    }
  });

  const saveClaimPhoto = () => {
    if (!phaseId) {
      setPhaseErrorMsg(t('phaseSelectionRequired'));
      return;
    }
    if ((isXactimateChecked || isSymbilityChecked) && !description && !editClaimPhoto) {
      setDescriptionErrorMsg(t('descriptionRequired'));
      return;
    }
    if (editClaimPhoto && !isVideo) {
      // edit new photo (before post)
      if (!editClaimPhoto.ctfileId && editClaimPhoto.tempId) {
        onEditNewPhoto();
      } else {
        onEditHandler();
      }
    } else if (selectedRoomId || isVideo) {
      onSubmitHandler();
    } else {
      setIsLevelsModalOpen(true);
    }
  };

  const onRoomSelected = async (room?: RoomModelType) => {
    setIsLevelsModalOpen(false);
    if (editClaimPhoto) {
      if (room) {
        // mutation to update selected photos
        const photoIds = [editClaimPhoto.ctfileId];
        await checkTokenExpiration();
        mutate({
          roomId: room.roomId,
          ctFileIds: photoIds,
        }, {
          onSuccess: () => {
            onDismiss();
            toast.success(t('imageAssociated'), { duration: 4000 });
          },
        });
      }
    } else {
      selectedRoom = room?.roomId;
      onSubmitHandler();
    }
  };

  const displayFileName = () => editClaimPhoto?.fileExtension ? 
    `${editClaimPhoto?.fileName}.${editClaimPhoto?.fileExtension}` :
    editClaimPhoto?.fileName;

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonLoading isOpen={isSaving} message={t('saving')} duration={8000} />
        <IonHeader>
          <IonToolbar class="header">
            <IonButtons slot="start">
              <IonButton onClick={() => onDismiss()}>
                <BackArrow fill="none" className="back-arrow" />
              </IonButton>
            </IonButtons>
            <IonTitle>{!isVideo ? t('importPhotos').toString() : t('importVideo').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => onDismiss()}>
                <div className="close-icon-container">
                  <IonIcon className="tools-close-button" icon={close} onClick={onDismiss} />
                </div>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          {!editClaimPhoto ?
            <div className="row upload-selected">
              <IonLabel className='upload-photo-label'>{!isVideo ? t('addInformation').toString() : ''}</IonLabel>
            </div>
            :
            <IonGrid className='ion-no-margin ion-no-padding'>
              <IonRow>
                <IonCol size="8">
                  <p>{displayFileName()}</p>
                </IonCol>
                {!isVideo &&
                  <IonCol size="4" className="move-to-col">
                    <IonButton className="ion-no-padding" onClick={() => openLevelsModal()} fill='clear'>{t('moveTo').toString()}</IonButton>
                  </IonCol>
                }
              </IonRow>
            </IonGrid>
          }
          <div className="row description-section">
            {filePath &&
              <FosCachedImage
                className='claim-photo-detail-img'
                src={filePath}
                onIonError={onImgError}
                folder={`${claimIndex}`}
              />
            }
          </div>
          <FosSelectItem
            {...register('phaseId')}
            placeholder={editClaimPhoto ? editClaimPhoto.phase : ''}
            label={t('phase')}
            options={phaseOptions}
            error={phaseErrorMsg}
          />
          <div className="description-section row">
            <IonLabel className="select-label">{t('description').toString()}</IonLabel>
            <IonTextarea
              {...register('description')}
              onIonChange={(d) => setValue('description', d.target.value || '')}
              className="description"
              autoGrow
            />
            {descriptionErrorMsg && <small className="ion-padding"><IonNote color="danger">{descriptionErrorMsg}</IonNote></small>}
          </div>
          <IonGrid className="ion-no-padding ion-no-margin">
            {!isVideo &&
              <IonRow className="ion-no-padding ion-no-margin">
                <IonCol className="ion-margin-end">
                  <IonItem lines="none" className="margin-bottom-20">
                    <IonCheckbox
                      checked={!!isShowInISIChecked}
                      value={!!isShowInISIChecked}
                      onIonChange={() => setIsShowInISIChecked(!isShowInISIChecked)}
                    />
                    <IonLabel>{t('showInISI').toString()}</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem lines="none" className="margin-bottom-20">
                    <IonCheckbox
                      disabled={!!editClaimPhoto}
                      checked={!!isTimeStampChecked}
                      value={!!isTimeStampChecked}
                      onIonChange={() => setIsTimeStampChecked(!isTimeStampChecked)}
                    />
                    <IonLabel>{t('timestamp').toString()}</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            }
            {isXactBound &&
              <>
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol>
                    <IonItem lines="none" className="margin-bottom-20">
                      <IonCheckbox
                        checked={!!isXactimateChecked}
                        value={!!isXactimateChecked}
                        onIonChange={() => setIsXactimateChecked(!isXactimateChecked)} />
                      <IonLabel>{t('submitToXact').toString()}</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonLabel className='xa-submitted-desc'>{t('xaSubmittedDescription').toString()}</IonLabel>
                </IonRow>
              </>
            }
            {isSymbilityBound &&
              <>
                <IonRow className="ion-no-padding ion-no-margin">
                  <IonCol>
                  <IonItem lines="none" className="margin-bottom-20">
                    <IonCheckbox
                      checked={!!isSymbilityChecked}
                      value={!!isSymbilityChecked}
                      onIonChange={() => setIsSymbilityChecked(!isSymbilityChecked)} />
                    <IonLabel>{t('submitToSymbility').toString()}</IonLabel>
                  </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonLabel className='xa-submitted-desc'>{t('symbilitySubmittedDescription').toString()}</IonLabel>
                </IonRow>
              </>
            }
          </IonGrid>
        </IonContent>
        <IonFooter class="ion-padding">
          <IonButton
            fill="solid"
            expand="block"
            onClick={saveClaimPhoto}>
            {editClaimPhoto || isVideo ? t('save').toString() : t('continue').toString()}
          </IonButton>
        </IonFooter>
      </IonModal>
      <IonModal isOpen={isLevelsModalOpen} class="custom-modal">
        <IonHeader class="ion-no-border">
          <IonToolbar class="header">
            <IonTitle>{t('addImagesTo').toString()}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsLevelsModalOpen(false)}>
                <FosCachedImage src={closeIcon} className="phase-close-icon" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <AddLevels onDone={(room) => onRoomSelected(room)} claimIndx={claimIndex} enableSkip />
      </IonModal>
      <IonLoading isOpen={isLoading} message={t('saving')} duration={2000} />
    </>
  );
};

export default AddPhotoToClaimForm;