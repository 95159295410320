import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonNote,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Option } from '../utils/Interfaces';
import { ReactComponent as BackArrow } from '../assets/icons/ic-back-arrow.svg';
import { useAuth } from '../hooks/authContext';
import getRestClient, { ClientType } from '../utils/AxiosClient';
import { Phase } from '../types/Phases';
import { ClaimFile } from '../types/ClaimFile';

import './AddDocumentToClaimForm.css';
import FosSelectItem from '../atom/FosSelectItem';
import FosCachedImage from '../components/FosCachedImage';
import { usePermissions, PermissionsList } from '../hooks/permissions';
import { ApiStatus } from '../pages/helper/Const';

const closeIcon = 'assets/images/icons/ic_close.png';

type Props = {
  isOpen: boolean;
  isEditMode?: boolean;
  existingDocumentData?: ClaimFile;
  claimDocuments: File[];
  claimIndex: number;
  regionId: number;
  phases: Phase[];
  documentTypeOptions: Option[];
  onSavingChange: (isSaving: boolean) => void;
  onDismiss: () => void;
  onSubmit: (success: boolean, msg: string) => void;
};

type FormData = {
  description: string;
  phaseId: string;
  docType: string;
};

const AddDocumentToClaimForm: React.FC<Props> = ({
  isOpen,
  isEditMode,
  existingDocumentData,
  claimDocuments,
  regionId,
  claimIndex,
  phases,
  documentTypeOptions,
  onSavingChange,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { userState, user } = useAuth()!;
  const [isXactBound, setIsXactBound] = useState(false);
  const [isSymbilityBound, setIsSymbilityBound] = useState(false);
  const [isXactimateChecked, setIsXactimateChecked] = useState(false);
  const [isSymbilityChecked, setIsSymbilityChecked] = useState(false);
  const restClient = getRestClient(userState, ClientType.FORM);
  const { permissions } = usePermissions();
  const isSendToXAEnabled = permissions.includes(PermissionsList.AllowSendToXA);

  const phaseOptions: Option[] = phases.map((phase) => ({ label: phase.phaseCode, value: phase.phaseIndx }));

  const schema = yup.object({
    description: yup
      .string()
      .max(255, t('descriptionMaxLengthIs255')),
    phaseId: yup
      .string()
      .required(t('phaseSelectionRequired')),
  });

  const { handleSubmit, register, setValue, watch, formState: { errors }, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { phaseId } = watch();

  useEffect(() => {
    if (isOpen && isEditMode && existingDocumentData) {
      setValue('phaseId', existingDocumentData?.phaseIndx?.toString() || '');
      setValue('description', existingDocumentData?.description || '');
      setValue('docType', existingDocumentData?.fileTypeCd || '');

      setIsXactimateChecked(existingDocumentData?.xactimate || false);
      setIsSymbilityChecked(existingDocumentData?.isSymbilityDoc || false);
    }
  }, [isOpen, isEditMode, existingDocumentData, setValue]);  

  useEffect(() => {
    const phase = phases.find(f => f.phaseIndx === Number(phaseId));
    setIsXactBound(!!phase?.linkedToXA && isSendToXAEnabled);
    setIsSymbilityBound(!!phase?.linkedToSymbility);
  }, [isSendToXAEnabled, phaseId, phases]);

  // clean form after close
  useEffect(() => {
    if (!isOpen) {
      setValue('phaseId', '');
      setValue('description', '');
      setValue('docType', '');
      setIsXactimateChecked(false);
      setIsSymbilityChecked(false);
      setIsXactBound(false);
      setIsSymbilityBound(false);
      clearErrors();
    }
  }, [clearErrors, isOpen, setValue]);
  const onSubmitHandler = handleSubmit(async (data: FormData) => {
    try {
      const formData = new FormData();
  
      formData.append('regionId', regionId.toString());
      formData.append('description', data.description);
      formData.append('fileTypeCd', data.docType);
  
      formData.append('uploadBy', user?.firstName && user?.lastName ? `${user?.firstName[0]}.${user?.lastName}` : '');
      
      claimDocuments.forEach(file => {
        formData.append('files', file);
      });
      
      formData.append('PhaseIndx', data.phaseId);

      const phase = phases.find(f => f.phaseIndx === Number(data.phaseId));

      if (phase?.linkedToXA && isXactimateChecked) {
        formData.append('sendToXact', 'true');
      }

      if (phase?.linkedToSymbility && isSymbilityChecked) {
        formData.append('sendToSymbility', 'true');
      }

      let result;
      onSavingChange(true);
      if (isEditMode && existingDocumentData?.ctfileId) {
        result = await restClient.post(`/common/claims/${claimIndex}/documents/${existingDocumentData?.ctfileId}`, formData);
      } else {
        result = await restClient.post(`/common/claims/${claimIndex}/documents`, formData);
      }

      onSubmit(result?.data?.status === ApiStatus.SUCCESS, '');      
    } catch (e: any) {
      let msg: string;
      if (e?.response?.data?.ErrorCode === 400) {
        msg = t('errorXSS');
      } else {
        msg = e?.response?.data?.Description;
      }
      const message = msg || t('');
      onSubmit(false, message);
    } finally {
      onSavingChange(false);
    }
    
  });

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar class="header">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>
              <BackArrow fill="none" className="back-arrow" />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('importDocuments').toString()}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <FosCachedImage src={closeIcon} className="phase-close-icon" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div className="row upload-selected">
          <IonLabel className='upload-photo-label'>
            {isEditMode ? `${existingDocumentData?.fileName}.${existingDocumentData?.fileExtension}` : claimDocuments?.[0]?.name}
          </IonLabel>
        </div>
          <FosSelectItem
            placeholder={isEditMode ? existingDocumentData?.phase : ''}
            {...register('phaseId')}
            label={t('phase')}
            options={phaseOptions}
            error={errors.phaseId?.message}
            required
          />
        <div className="description-section row">
          <IonLabel className="select-label">{t('description').toString()}</IonLabel>
          <IonTextarea
            {...register('description')}
            className="description"
            autoGrow
          />
          {errors.description && <small className="ion-padding"><IonNote color="danger">{errors.description.message}</IonNote></small>}
        </div>
        <FosSelectItem 
          placeholder={isEditMode ? existingDocumentData?.fileTypeCd : ''}
          label={t('type')}
          options={documentTypeOptions}
          {...register('docType')} />
        {isXactBound &&
        <>
          <IonRow className="ion-no-padding ion-no-margin">
            <IonCol>
              <IonItem lines="none" className="margin-bottom-20">
                <IonCheckbox
                  disabled={isEditMode && existingDocumentData?.xactimate}
                  checked={!!isXactimateChecked}
                  value={!!isXactimateChecked}
                  onIonChange={() => setIsXactimateChecked(!isXactimateChecked)}
                />
                <IonLabel>{t('submitToXact').toString()}</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonLabel className='xa-submitted-desc'>{t('xaSubmittedDescription').toString()}</IonLabel>
          </IonRow>
        </>
        }
        {isSymbilityBound &&
        <>
          <IonRow className="ion-no-padding ion-no-margin">
            <IonCol>
              <IonItem lines="none" className="margin-bottom-20">
                <IonCheckbox
                  disabled={isEditMode && existingDocumentData?.isSymbilityDoc}
                  checked={!!isSymbilityChecked}
                  value={!!isSymbilityChecked}
                  onIonChange={() => setIsSymbilityChecked(!isSymbilityChecked)}
                />
                <IonLabel>{t('submitToSymbility').toString()}</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonLabel className='xa-submitted-desc'>{t('symbilitySubmittedDescription').toString()}</IonLabel>
          </IonRow>
        </>
        }
      </IonContent>
      <IonFooter class="ion-padding">
        <IonButton
          fill="solid"
          expand="block"
          onClick={() => onSubmitHandler()}>
          {t('save').toString()}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default AddDocumentToClaimForm;