import React from 'react';
import { useTranslation } from 'react-i18next';
import './NoPhotos.css';

type Props = {
  
};

const NoVideos: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div slot="fixed" className="upload-wrapper no-photos">
      <div className="upload-heading">
        <div>{t('noVideos').toString()}</div>
      </div>
    </div>
  );
};

export default NoVideos;