import React from 'react';
import { IonIcon, IonLabel, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { t } from 'i18next';
import { ClaimPhotoActiveOption, ClaimPhotoMenuTabs } from '../pages/helper/ClaimPhotosHelper';
import { PhotoLevels, PhotoRooms } from '../pages/helper/LevelRoomsPhotos';

const selected = 'inspection-menu-label menu-text-size inspection-menu-selected';
const unselected = 'inspection-menu-label menu-text-size inspection-menu-unselected';

type Props = {
  photoSelectedOptions: ClaimPhotoActiveOption;
  selectedLevel: PhotoLevels | undefined;
  selectedRoom: PhotoRooms | undefined;
  backToRooms: () => void;
  setPhotoSelectedOptions: (arg0: ClaimPhotoActiveOption) => void;
};

const ClaimPhotosHeader: React.FC<Props> = ({ photoSelectedOptions, selectedLevel, selectedRoom, backToRooms, setPhotoSelectedOptions }) =>
  <IonToolbar className="ion-no-padding" slot='fixed'>
    {photoSelectedOptions === ClaimPhotoMenuTabs.RoomDetails ?
      <div className='claim-menu-container'>
        <IonIcon onClick={() => backToRooms()} className='arrow-back-icon' icon={arrowBack} />
        <IonLabel
          className={`${selected} level-room-label`}
        >
          {`${selectedLevel?.name} / ${selectedRoom?.name}`}
        </IonLabel>
      </div>
      :
      <div className='claim-menu-container'>   
        <IonLabel
          className={photoSelectedOptions === ClaimPhotoMenuTabs.Rooms ? selected : unselected}
          onClick={() => setPhotoSelectedOptions(ClaimPhotoMenuTabs.Rooms)}
        >
          {t('rooms').toString()}
        </IonLabel>
        <IonLabel
          className={photoSelectedOptions === ClaimPhotoMenuTabs.All ? selected : unselected}
          onClick={() => setPhotoSelectedOptions(ClaimPhotoMenuTabs.All)}
        >
          {t('all').toString()}
        </IonLabel>
        <IonLabel
          className={photoSelectedOptions === ClaimPhotoMenuTabs.Videos ? selected : unselected}
          onClick={() => setPhotoSelectedOptions(ClaimPhotoMenuTabs.Videos)}
        >
          {t('videos').toString()}
        </IonLabel>
      </div>
    }

  </IonToolbar>;

export default ClaimPhotosHeader;